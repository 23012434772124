<template>
  <div class="news_card" @click="eventClick">
    <span>{{ data.publishDate && data.publishDate.split(' ')[0] }}</span>
    <div>
      <span>{{ data.contentType == 0 ? '通知公告' : data.contentType == 1 ? '行业动态' : data.contentType == 2 ?  '招纳贤士' : '培训信息' }}</span>
      <h3>{{ data.title }}</h3>
      <p v-html="utils.showChange(data.summary)"></p>
    </div>
    <img v-if="data.picture" :src="baseUrl + data.picture" />
  </div>
</template> 

<script>
import utils from '@/assets/js/utils';
export default {
  props: ['data'],
  computed: {
    utils() { return utils }
  },
  methods: {
    eventClick() {
      window.open(`/notificationInformationDetail/${this.data.id}`)
    },
    showChange(item) {
      if(item && (/\r\n/g.test(item) || /\n/g.test(item) || /(\r\n)|(\n)/g.test(item) || /\s/g.test(item) )) {
        item = item.replace(/\r\n/g,"<br>")
        item = item.replace(/\n/g,"<br>");
        item = item.replace(/(\r\n)|(\n)/g,'<br>');
        item = item.replace(/\s/g,"&nbsp;");
      }
      return item
    },
  }
}
</script>

<style lang='scss'>
  .news_card {
    display: flex;
    padding: 30px;
    border-bottom: 1px solid #EBEBEB;
    cursor: pointer;

    &:hover {
      & > div {
        & > h3 {
          color: #0072EF;
        }
      }
    }

    & > span {
      display: block;
      height: 24px;
      padding: 2px 18px 2px 0;
      border-right: 1px solid #dcdfe6;
      margin-right: 22px;
      line-height: 20px;
      width: 110px;
      font-size: 14px;
      font-weight: 600;
      color: #303133;
    }

    & > div {
      width: 800px;
      margin-right: 30px;

      & > span {
        width: 76px;
        height: 24px;
        background: #f2f8ff;
        border-radius: 4px;
        line-height: 24px;
        font-size: 14px;
        color: #0072EF;
        margin-bottom: 15px;
        display: block;
        text-align: center;
      }

      & > h3 {
        line-height: 25px;
        font-size: 18px;
        color: #000000;
        margin-bottom: 10px;
      }

      & > p {
        font-size: 14px;
        color: #7b7b7b;
        line-height: 24px;
      }
    }

    & > img {
      width: 160px;
      height: 100px;
      background: rgba(0,0,0,0.00);
      border-radius: 4px; 
      margin-top: 40px;
    }
  }
</style>