<template>
  <div class="type_search">
    <h3>分类</h3>
    <div class="item_list">
      <span 
        :class="{'active': tab == item.key }" 
        v-for="item in dataList[type]" 
        :key="item.key"
        @click="eventClick(item.key, item)"><svg-icon :icon-class="item.icon"></svg-icon>{{ item.title }}</span>
    </div>
    <el-input 
      v-model="value" 
      placeholder="请输入关键词" 
      size="large"
      @keydown="keydownClick"
      @input="inputClick">
      <template #suffix>
        <svg-icon icon-class="search" @click="searchClick"></svg-icon>
      </template>
    </el-input>
  </div>
</template>

<script>
export default {
  props: ['type', 'tabIndex'],
  data() {
    return {
      dataList: {
        news: [
          { title: '全部', key: 'all', back: '全部最新资讯', icon: 'notice' },
          { title: '通知公告', key: 0, back: '通知公告', icon: 'manual' },
          { title: '行业动态', key: 1, back: '行业动态', icon: 'explore' },
          { title: '培训信息', key: 3, back: '培训信息', icon: 'book-mark' },
          { title: '招贤纳士', key: 2, back: '招贤纳士', icon: 'recruit' },
        ]
      },
      value: '',
      tab: this.tabIndex || 'all'
    }
  },
  watch: {
    tabIndex: {
      immediate: true,
      handler(n, o) {
        if(n && n != o) {
          this.tab = n;
        }
      }
    }
  },
  methods: {
    eventClick(index, item) {
      this.tab = index
      this.$emit('back', item)
    },
    keydownClick(e) {
      if(e.keyCode == 13) {
        this.searchClick()
      }
    },
    searchClick() {
      if(!this.value || !this.value.trim()) {
        return this.$message.error('请输入搜索内容')
      }else {
        this.$emit('search', this.value)
      }
    },
    inputClick(e) {
      if(!e) this.$emit('search', null)
    }
  }
  
}
</script>

<style lang='scss'>
  .type_search {
    width: 1180px;
    height: 60px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 10px;
    padding: 10px 10px 10px 30px;
    display: flex;
    align-items: center;

    & > h3 {
      line-height: 20px;
      font-size: 14px;
      color: #1d2129;
      margin-right: 30px;
    }

    .item_list {
      width: 850px;
      margin-right: 30px;

      & > span {
        display: inline-block;
        font-size: 14px;
        color: #4e5969;
        padding: 0px 15px;
        background: #f8f9fb;
        border-radius: 4px;
        line-height: 28px;
        text-align: center;
        margin-right: 12px;
        cursor: pointer;

        .svg-icon {
          width: 14px;
          height: 14px;
          margin-right: 8px;
          color: #4E5969;
          display: inline-block;
          vertical-align: -2px;
        }

        &:hover {
          background: #0072ef;
          color: #fff;

          .svg-icon {
            color: #fff;
          }
        }
      }

      .active {
        background: #0072ef;
        color: #fff;

        .svg-icon {
          color: #fff;
        }
      }
    }

    .el-input {
      width: 200px;

      .svg-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
</style>