<template>
  <div id="notificationInformation">
    <TypeAndSearch :tabIndex="type ? type : 0" type="news" @back="titleEvent" @search="searchClick"></TypeAndSearch>
    <CommonTitle>
      <template v-slot:title>{{ title }}</template>
      <template v-slot:number>{{ number }}</template>
    </CommonTitle>
    <NewsTop></NewsTop>
    <div class="list">
      <template v-if="dataList.length > 0">
        <NewsCards v-for="(item, index) in dataList" :key="index" :data="item"></NewsCards>
        <Pagination 
          :pageSize="pageSize"
          :number="number"
          :pageNumber="pageNumber"
        ></Pagination>
      </template>
      <NoData v-else></NoData>
      
    </div>
  </div>
</template>

<script>
import TypeAndSearch from './components/TypeAndSearch.vue'
import NewsCards from './components/NewsCards.vue'
import NewsTop from './components/NewsTop.vue'
import CommonTitle from '@/components/CommonTitle.vue'
import Pagination from '@/components/Pagination.vue'
export default {
  components: {
    TypeAndSearch,
    CommonTitle,
    NewsCards,
    Pagination,
    NewsTop
  },
  data() {
    return {
      title: '全部资讯',
      number: 0,
      pageSize: 20,
      pageNumber: parseInt(this.$route.query.pager) || 1,
      dataList: [],
      type: 'all',
      searchTitle: null
    }
  },
  watch: {
    '$store.state.newsType': {
      handler(n) {
        if(n) {
          this.type = n[0] == 'a' ? 'all' : n[0];
          this.showPage()
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.showPage()
  },
  methods: {
    titleEvent(data) {
      this.title = data.back;
      this.type = data.key;
      this.pageNumber = 1;
      this.showPage();
    },

    searchClick(value) {
      this.searchTitle = value;
      this.pageNumber = 1;
      this.showPage();
    },
    
    showPage() {
      let type = this.$store.state.newsType && this.$store.state.newsType[0]
      this.$API.ArticleList({
        status: 0,
        contentType: this.type == 'all' ? null : this.type,
        pageNum: this.pageNumber,
        pageSize: this.pageSize,
        title: this.searchTitle
      }).then(res => {
        if(res.data.code == 200) {
          this.dataList = res.data.data.list;
          this.number = res.data.data.total
        }
      })
    }
  }
}
</script>

<style lang='scss'>
  #notificationInformation {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 10px 50px;

    .common_title {
      margin-top: 30px;
      margin-bottom: 20px;
    }

    .list {
      background: #ffffff;
      border: 1px solid #ebebeb;
      border-radius: 10px;
      overflow: hidden;

    }
  }
</style>